.not-found {
    height: calc(100vh - (var(--nav-bar-height) + 10px)); 
    /*
        Reason why there is only (+10px) and not (+20px):
        100vh it's 100% of viewport height all items that don't have position property set to fixed (or absolute in viewport)
        have 10px padding from top and bottom. 
        But nav-bar's position property is fixed and top: 0; So its height includes the top 10px. So there is added only
        bottom 10px;  
    */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;

    font-size: 30px;
    font-family: "Inter", sans-serif;
}

.not-found .sorry{
    margin-bottom: 20px;
}

.not-found .cant-find{
    margin-bottom: 20px;
    font-weight:bolder;
}

.not-found .suggestion{
    font-size: 20px;
    color: rgb(100, 100, 100);
}