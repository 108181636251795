.cg-wrap {
    box-sizing: content-box;
    position: relative;
    display: block;
  }
  
  cg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
  }
  
  cg-board {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 0;
    background-size: cover;
  }
  
  .cg-wrap.manipulable cg-board {
    cursor: pointer;
  }
  
  cg-board square {
    position: absolute;
    top: 0;
    left: 0;
    width: 12.5%;
    height: 12.5%;
    pointer-events: none;
  }
  
  cg-board square.move-dest {
    pointer-events: auto;
  }
  
  cg-board square.last-move {
    will-change: transform;
  }
  
  .cg-wrap piece {
    position: absolute;
    top: 0;
    left: 0;
    width: 12.5%;
    height: 12.5%;
    background-size: cover;
    z-index: 2;
    will-change: transform;
    pointer-events: none;
  }
  
  cg-board piece.dragging {
    cursor: move;
    /* !important to override z-index from 3D piece inline style */
    z-index: 11 !important;
  }
  
  piece.anim {
    z-index: 8;
  }
  
  piece.fading {
    z-index: 1;
    opacity: 0.5;
  }
  
  .cg-wrap piece.ghost {
    opacity: 0.3;
  }
  
  .cg-wrap piece svg {
    overflow: hidden;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
    opacity: 0.6;
  }
  
  .cg-wrap cg-auto-pieces,
  .cg-wrap .cg-shapes,
  .cg-wrap .cg-custom-svgs {
    overflow: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .cg-wrap cg-auto-pieces {
    z-index: 2;
  }
  
  .cg-wrap cg-auto-pieces piece {
    opacity: 0.3;
  }
  
  .cg-wrap .cg-shapes {
    overflow: hidden;
    opacity: 0.6;
    z-index: 2;
  }
  
  .cg-wrap .cg-custom-svgs {
    /* over piece.anim = 8, but under piece.dragging = 11 */
    z-index: 9;
  }
  
  .cg-wrap .cg-custom-svgs svg {
    overflow: visible;
  }
  
  .cg-wrap coords {
    position: absolute;
    display: flex;
    pointer-events: none;
    opacity: 0.8;
    font-family: sans-serif;
    font-size: 9px;
  }
  
  .cg-wrap coords.ranks {
    left: 0px;
    top:  min(calc(-0.044 * var(--edge)), -21.12px);
    flex-flow: column-reverse;
    height: 100%;
    width: 12px;
    text-align: center;
  }
  
  .cg-wrap coords.ranks.black {
    flex-flow: column;
  }
  
  .cg-wrap coords.ranks.left {
    left: -15px;
    align-items: flex-end;
  }
  
  .cg-wrap coords.files {
    bottom: 0px;
    left: -4px;
    flex-flow: row;
    width: 100%;
    height: 12px;
    text-transform: uppercase;
    text-align: right;
  }
  
  .cg-wrap coords.files.black {
    flex-flow: row-reverse;
  }
  
  .cg-wrap coords coord {
    flex: 1 1 auto;
  }
  
  .cg-wrap coords.ranks coord {
    transform: translateY(39%);
  }