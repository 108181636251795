.cg-wrap cg-board {
    background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgeG1sbnM6eD0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgIHZpZXdCb3g9IjAgMCA4IDgiICBzaGFwZS1yZW5kZXJpbmc9Imdlb21ldHJpY1ByZWNpc2lvbiI+PHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iOCIgZmlsbD0iI2I1ODg2MyIvPjxnIGlkPSJhIj4gIDxnIGlkPSJiIj4gICAgPGcgaWQ9ImMiPiAgICAgIDxnIGlkPSJkIj4gICAgICAgIDxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNmMGQ5YjUiIGlkPSJlIi8+ICAgICAgICA8dXNlIHg9IjIiIGhyZWY9IiNlIiB4OmhyZWY9IiNlIi8+ICAgICAgPC9nPiAgICAgIDx1c2UgeD0iNCIgaHJlZj0iI2QiIHg6aHJlZj0iI2QiLz4gICAgPC9nPiAgICA8dXNlIHg9IjEiIHk9IjEiIGhyZWY9IiNjIiB4OmhyZWY9IiNjIi8+ICA8L2c+ICA8dXNlIHk9IjIiIGhyZWY9IiNiIiB4OmhyZWY9IiNiIi8+PC9nPjx1c2UgeT0iNCIgaHJlZj0iI2EiIHg6aHJlZj0iI2EiLz48L3N2Zz4=");
}

/** Interactive board square colors */
cg-board square.move-dest {
  background: radial-gradient(rgba(20, 85, 30, 0.5) 22%, #208530 0, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 0);
}
cg-board square.premove-dest {
  background: radial-gradient(rgba(20, 30, 85, 0.5) 22%, #203085 0, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 0);
}
cg-board square.oc.move-dest {
  background: radial-gradient(transparent 0%, transparent 80%, rgba(20, 85, 0, 0.3) 80%);
}
cg-board square.oc.premove-dest {
  background: radial-gradient(transparent 0%, transparent 80%, rgba(20, 30, 85, 0.2) 80%);
}
cg-board square.move-dest:hover {
  background: rgba(20, 85, 30, 0.3);
}
cg-board square.premove-dest:hover {
  background: rgba(20, 30, 85, 0.2);
}
cg-board square.last-move {
  background-color: rgba(155, 199, 0, 0.41);
}
cg-board square.selected {
  background-color: rgba(20, 85, 30, 0.5);
}
cg-board square.check {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 0, 0, 1) 0%,
    rgba(231, 0, 0, 1) 25%,
    rgba(169, 0, 0, 0) 89%,
    rgba(158, 0, 0, 0) 100%
  );
}
cg-board square.current-premove {
  background-color: rgba(20, 30, 85, 0.5);
}

/** Alternating colors in rank/file labels */
.cg-wrap.orientation-white coords.ranks coord:nth-child(2n),
.cg-wrap.orientation-white coords.files coord:nth-child(2n),
.cg-wrap.orientation-black coords.ranks coord:nth-child(2n + 1),
.cg-wrap.orientation-black coords.files coord:nth-child(2n + 1) {
  color: rgba(72, 72, 72, 0.8);
}

.cg-wrap.orientation-black coords.ranks coord:nth-child(2n),
.cg-wrap.orientation-black coords.files coord:nth-child(2n),
.cg-wrap.orientation-white coords.ranks coord:nth-child(2n + 1),
.cg-wrap.orientation-white coords.files coord:nth-child(2n + 1) {
  color: rgba(255, 255, 255, 0.8);
} 