.newFlashcard-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.newFlashcard-form { 
    height: 600px;
    min-width: 450px;
    width: 90vw;                               /* I don't think it's right. */
    max-width: 590px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 1.05em;

    background-color: white;
    padding: 20px;
    border-radius: 20px;
    border: none;
}

.newFlashcard-form .param {
    text-align: center;
    font-size: 1.5em;
}

.newFlashcard-form .pgn-text {
    display: flex;
    align-items: flex-end;
}

.newFlashcard-form .lichess-link {
    font-weight: 500;
}

.newFlashcard-form textarea {
    width: 99%;
    height: 40px;
    resize: none;

    border-radius: 5px;
    border: 3.15px solid rgb(220, 220, 220);
}

.newFlashcard-form .title-textarea { 
    height: 20px;
}

.newFlashcard-form .pgn-textarea {
    height: 80px;
}

.newFlashcard-form .text {
    margin-bottom: 7px;
    font-size: 15px;
    padding: 5px;
}

.newFlashcard-form .text .text-1st-div {
    margin-bottom: 10px;
}

.newFlashcard-form .move-input {
    width: 54px;
    height: 25.5px;
    border: 1px solid rgb(100, 100, 100);
    border-radius: 5px;
    padding-left: 4px;
}

.newFlashcard-form select { 
    padding: 1px;
    width: 62px;
    height: 30px;
    border-radius: 5px;
}

.newFlashcard-form table {
    width: fit-content;
}

.newFlashcard-form td {
    padding: 5px;
}

.newFlashcard-form .create-button{
    width: 100%;
}
