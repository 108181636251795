/* Top Line */
.flashcard {
    padding-top: 20px;
}

.flashcard .top-line { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flashcard .top-line .title {
    color: #4d4d4d;
    font-size: 35px;
    white-space: nowrap;
    text-shadow: 0 1px 0 #fff;

    padding: 10px 20px;
    box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
    background-color: white;
    margin-bottom: 10px;
}

/* ////////////////////////////////////////////// */

.flashcard .middle-line { 
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    height: fit-content;
}

.middle-line .description-box {
    width: 350px;
    margin-bottom: 10px;
}

.middle-line .description-box .description-part-of-flashcard {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
}

.middle-line .board-buttons-with-pgn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.middle-line .my_board { 
    --edge: min(calc(100vh - 300px), calc(100vw - 720px));
    width: var(--edge);
    height: var(--edge);
    min-width: 480px;
    min-height: 480px;
    margin-bottom: 10px;
    user-select: none;
}

.middle-line .pgn-box { 
    width: 330px;
    user-select: none;
}

.description-box .descriptionTitle {
    color: #4d4d4d;
    font-size: 30px;
    margin-bottom: 10px;
}

.pgn-box .backgr {
    --gray-100: rgb(240, 240, 240);
    background-color: white;
    border: 5px solid rgb(220, 220, 220);
    border-radius: 5px;
    min-height: 21.6px;
}

.flashcard .buttonBox {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.buttonBox button {
    background: linear-gradient(to bottom, white 0%, rgb(207, 207, 207) 100%);
    height: 35px;
    min-width: 100px;

    color: rgb(120, 120, 120);
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;

    border: 2px solid rgb(120, 120, 120);
    border-radius: 5px;

    cursor: pointer;
    user-select: none;
}

.buttonBox button:hover {
    background: rgba(250, 250, 250, 0.171);
}

.buttonBox button:active {
    opacity: 0.8;
}

.buttonBox .autoplayToggled {
    border-color: var(--green1);
}

.flashcard .gj {
    margin: auto;
    text-align: center;
    color: var(--green1);
    font-size: 30px;
}

@media (max-width: 950px) {
    .flashcard .top-line .title {
        font-size: 28px;
    }
}

@media (max-width: 850px) {
    .pgn-box {
        display: none;
    }
}

@media (max-width: 775px) {
    .flashcard .top-line .title {
        font-size: 24px;
    }
}

@media (max-width: 650px) {
    .flashcard .top-line .title {
        font-size: 18px;
    }
}